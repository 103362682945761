import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageHeader from "../../components/PageHeader"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Testimonial from "../../components/Testimonial"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"
import {
  animeFadeUp,
  animeSlideOutLeft,
  animeSlideOutBot,
  animeFadeIn
} from "../../utils/Animations"

const ServiceMariagePage = ({data, transitionStatus}) => {
  const testimonials = [
    {
      name: 'Mickael & Jérôme',
      text: `La prestation de Belladrinks a été absolument géniale ! De la demande de devis jusqu'à la prestations le jour J, tout était très professionnel ! Nos invités et nous avons adoré les cocktails et le barman / flair nous a fait vivre un super moment !`
    },
    {
      name: 'Elisabeth & Jean-Philippe',
      text: `Une entreprise qui a un vrai savoir faire et un sens du service orienté client. Ils se sont conduit en tant que vrai partenaire pour mon mariage.`
    },
    {
      name: 'Laetitia & Mathieu',
      text: `Tony et son équipe ont été parfait pour notre mariage. Service rapide avec le sourire, bar dans notre thème et cocktails délicieux. Merci à vous.`
    },
    {
      name: 'Victoria & Julien',
      text: `Nous avons mandaté Belladrinks pour la soirée de notre mariage. Le bar était installé près de la piste de danse et grâce aux excellents cockails proposés, l'ensemble de nos invités ont passé une soirée géniale.`
    }
  ]
  return (
    <Layout>
      <SEO
        title="Bar à cocktails mariage - Belladrinks bar à cocktails événementiel"
        description="À la recherche d'une animation originale pour votre mariage ? Offrez-vous un véritable bar à cocktails pour le vin d'honneur ou la soirée de votre mariage."
      />
      <PageHeader data={data} transitionStatus={transitionStatus} title={"Bar à cocktails mariage"}/>
      <section className="py-16 md:py-40 2xl:py-56 2xl:pb-48">
        <InView triggerOnce={true} threshold={0.5} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              animate={inView ? "visible" : "hidden"}
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.4 }
                }
              }}
              className="main-container grid grid-cols-1 xl:grid-cols-2 lg:gap-4" ref={ref}
            >
              <div>
                <motion.h2 variants={animeFadeUp} className="display-1 mb-8 pr-10">Service de bar à cocktails éphémère pour les mariages.</motion.h2>
                <motion.div
                  variants={{
                    hidden:{
                      width: 0
                    },
                    visible: {
                      width: '7rem',
                      transition: {
                        ease: "easeOut",
                        duration: "0.6"
                      }
                    }
                  }}
                  className="border-b-4 border-copper mt-2 mb-8"
                />
              </div>
              <div>
                <motion.p variants={animeFadeUp} className="lead">
                  Être présent le jour J avec un haut niveau de prestation, c’est tout le sens de notre service bar à cocktails. Conscient de l’exigence et du haut niveau d’attente autour de votre mariage, nous préparons cette journée avec vous pour ne rien laisser au hasard.
                  Faire confiance à notre équipe garantira que vous et vos invités profiterez d'une expérience de service haut de gamme et sans fausse note.
                </motion.p>
              </div>
            </motion.div>
          )}
        </InView>
      </section>
      <section className="main-container pb-40">
        <div className="grid grid-cols-2 gap-2 lg:grid-rows-2 lg:grid-flow-row lg:auto-rows-fr">
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 lg:h-auto lg:row-span-2 overflow-hidden">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutLeft} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.image1.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 lg:h-auto overflow-hidden">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutLeft} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.image2.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <motion.div
                ref={ref}
                animate={inView ? "visible" : "hidden"}
                initial={false}
                variants={{
                  visible: {
                    transition: { staggerChildren: 0.4 }
                  }
                }}
                className="bg-white py-10 px-8 md:p-16 md:py-20 2xl:p-20 2xl:py-32 col-span-2 lg:col-auto"
              >
                <motion.h2 variants={animeFadeUp} className="display-2 mb-6">10 ans d'expérience</motion.h2>
                <motion.p variants={animeFadeUp} className="lead">
                  Nous accompagnons depuis plus de 10 ans les futurs mariés dans la réussite de leur mariage. Nous sommes très exigeant sur la formation de notre personnel ainsi que sur la qualité de nos matières premières.
                </motion.p>
              </motion.div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 lg:h-auto overflow-hidden col-span-2 ">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutLeft} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.image3.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <motion.div
                ref={ref}
                animate={inView ? "visible" : "hidden"}
                initial={false}
                variants={{
                  visible: {
                    transition: { staggerChildren: 0.4 }
                  }
                }}
                className="bg-white py-10 px-8 md:p-16 md:py-20 2xl:p-20 2xl:py-32 col-span-2 lg:col-auto"
              >
                <motion.h2 variants={animeFadeUp} className="display-2 mb-6">Toujours plus engagés</motion.h2>
                <motion.p variants={animeFadeUp} className="lead">
                  Depuis 2016, nous utilisons des pailles écologiques obtenu grâce à de l'amidon de maïs. Nous favorisons les circuits courts ainsi que les produits de saisons et transformons un maximum de matières premières dans nos locaux.
                </motion.p>
              </motion.div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 col-span-2 lg:col-auto lg:h-auto overflow-hidden">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutBot} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.image4.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
        </div>
        <div className="flex justify-end mt-20">
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <motion.div
                ref={ref}
                initial={false}
                animate={inView ? "visible" : "hidden"}
                variants={animeFadeUp}
              >
                <TransitionLink
                  className="link"
                  to="/contact/"
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  Demandez un devis
                </TransitionLink>
              </motion.div>
            )}
          </InView>

        </div>
      </section>
      <section className="relative py-20 md:py-36 lg:py-40 2xl:py-52 bg-bigstone-light">
        <InView triggerOnce={true} threshold={0.25} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              ref={ref}
              className="main-container"
              animate={inView ? "visible" : "hidden"}
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.4 }
                }
              }}
            >
              <motion.h2 variants={animeFadeUp} className="display-1 mb-8 text-white">Ils nous ont dit oui</motion.h2>
              <motion.div variants={animeFadeIn}>
                <Testimonial testimonials={testimonials}/>
              </motion.div>
            </motion.div>
          )}
        </InView>
      </section>
      <section className="py-20 md:py-36 lg:py-40 2xl:py-52">
        <InView triggerOnce={true} threshold={0.25} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              ref={ref}
              animate={inView ? "visible" : "hidden"}
              className="main-container text-center"
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.2 }
                }
              }}
            >
              <motion.h2 variants={animeFadeIn} className="display-1">Vous organisez un mariage ?</motion.h2>
              <motion.p variants={animeFadeIn} className="lead mb-8">Contactez notre équipe et obtenez un devis rapidement.</motion.p>
              <motion.div variants={animeFadeIn}>
                <TransitionLink
                  className="button bg-copper text-white hover:bg-white hover:text-copper mr-2 md:mr-8"
                  to="/contact/"
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  Contactez-nous
                </TransitionLink>
              </motion.div>
            </motion.div>
          )}
        </InView>
      </section>
    </Layout>
  )
}

export default ServiceMariagePage

export const query = graphql`
    query {
        backgroundHeader: file(relativePath: { eq: "mariage.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        backgroundCocktail: file(relativePath: { eq: "cocktails/bunnybunnyboom.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        image1: file(relativePath: { eq: "services/mariage/maks-styazhkin-F6SBmZjXFYw-unsplash.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        image2: file(relativePath: { eq: "services/mariage/louis-hansel-shotsoflouis-V4WpQQk6Lk8-unsplash.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        image3: file(relativePath: { eq: "services/mariage/kelly-sikkema-AgJdd_HgFPw-unsplash.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        image4: file(relativePath: { eq: "services/mariage/emily-andreeva-hXg4gGjIfhw-unsplash.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`